import React, {useContext, useState} from "react";
import "./Gpsr.scss";
import {I18nContext, T} from "@deity-io/falcon-i18n";
import DocumentIcon from "../../assets/document.webp";
import clsx from "clsx";

const Gpsr = ({product}) => {
    const { t } = useContext(I18nContext);
    const [activeTab, setActiveTab] = useState("safety");

    const {attachments = [], safety_info, emlabels = []} = product;



    const gpsrAttachments = attachments.filter((attachment) => {
        return attachment['label'] == 'gpsr'
    })
    console.log('gpsrAttachments',gpsrAttachments)

    let labelProducer = emlabels.find((label) => {
        return label['attribute_code'] == 'proizvodac';
    });
    if (!labelProducer) {
        labelProducer = { description:  t('gpsr.emptyProducer') };
    }

    let labelImporter = emlabels.find((label) => {
        return label['attribute_code'] == 'importer';
    });

    if (!labelImporter) {
        labelImporter = { description: t('gpsr.emptyImporter') };
    }


    let safetyInfoContent
    if(!safety_info && gpsrAttachments.length==0){
        safetyInfoContent = safety_info || "At the moment, safety information is not available.";
    }
    console.log('gpsrAttachments',gpsrAttachments)
    // return (<div>aaaaaaaaaaaa</div>)
    return (
        <div id="gpsr">
            {
                labelProducer &&
                <div className='tabs'>
                    <div className={clsx('tab', activeTab === 'safety' && 'active')} onClick={() => setActiveTab('safety')}><T id="gpsr.safetyHeader"/></div>
                    <div className={clsx('tab', activeTab === 'manufacturer' && 'active')} onClick={() => setActiveTab('manufacturer')}><T id="gpsr.manufacturerHeader"/></div>
                    <div className={clsx('tab', activeTab === 'importer' && 'active')} onClick={() => setActiveTab('importer')}><T id="gpsr.importerHeader"/></div>
                </div>
            }
            <div className="tab-content">
                {activeTab === "safety" && (
                    <div>
                        {!safety_info && gpsrAttachments.length==0 && <T id='gpsr.emptySafety'/>}

                        <p dangerouslySetInnerHTML={{__html: safety_info}}/>
                        <ul className={'attachments'}>
                            {
                                gpsrAttachments.map((attachment) => {
                                    return (
                                        <li
                                            onClick={() => window.open(attachment.link ? attachment.link : attachment.frontend_url)}
                                        ><img src={DocumentIcon} alt=""/></li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                )
                }
                {activeTab === "manufacturer" && labelProducer && <div dangerouslySetInnerHTML={{__html: labelProducer.description}}/>}
                {activeTab === "importer" && labelImporter && <div dangerouslySetInnerHTML={{__html: labelImporter.description}}/>}

            </div>
        </div>
    )
}

export default Gpsr